import styled from "styled-components";
import * as Css from "../../../src/Components/Css";

export const Dialog = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translateX(-100vw);
  transition: 0s ease-in transform 0.5s;

  &.active {
    transform: translateX(0);
    transition: 0s ease-in transform 0s;
  }

  & .backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: ${props => (props.open ? 1 : 0)};
    transition: 0.2s ease-in opacity;
  }

  & .container {
    background-color: white;
    width: 100%;
    max-width: 735px;
    min-height: 200px;
    max-height: calc(100% - 60px);
    border-top: 9px solid #8596a6;
    border-radius: 5px;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 17px 23px;

    & > .content {
      overflow: auto;
      width: 100%;

      & .title {
        margin-bottom: 29px;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 16px;
        white-space: pre-wrap;
        line-height: 24px;
        letter-spacing: 2.4px;
        font-size: 24px;
        color: #19233b;
      }

      & .text-container {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1.6px;
        font-size: 16px;
        color: #19233b;
      }

      & .form-section {
        border-bottom: 1px solid #eaeaea;
        height: 380px;
        overflow: scroll;
        display: flex;
        flex-direction: column;

        & > .form-container {
          width: 100%;
          display: flex;
          align-items: center;

          & > .form-content {
            max-width: 365px;
            width: 100%;
          }

          @media screen and (max-width: 600px) {
            justify-content: center;
            flex-wrap: wrap;
          }
        }

        & > .shoes-container {
          width: 100%;
          border-top: 1px solid #eaeaea;
          padding: 22px 0px;
          display: flex;
          align-items: center;

          & .shoes-content {
            max-width: 365px;
            width: 100%;
          }

          @media screen and (max-width: 600px) {
            justify-content: center;
            flex-wrap: wrap;
          }
        }

        @media screen and (max-width: 320px) {
          height: 250px;
        }
      }

      & .table-section {
        border-bottom: 1px solid #eaeaea;
        height: 380px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
      }

      & .bottom-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 21px;

        & > .message {
          font-size: 12px;
          color: #929292;
        }

        & > .buttons {
          display: flex;
          justify-content: center;
        }

        @media screen and (max-width: 599px) {
          flex-direction: column;

          & > .message {
            margin-bottom: 10px;
            width: 100%;
            justify-self: flex-start;
          }

          & > .buttons {
            width: 100%;
            justify-content: flex-end;
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      min-width: initial;
      margin: 10px;
    }
  }

  ${props => props.css || ""};
`;

export const FormField = styled.div`
  max-width: 308px;
  width: 100%;
  min-height: 34px;
  display: flex;
  align-items: center;

  & > .label {
    margin-right: 10px;
    min-width: 90px;
    letter-spacing: 1.6px;
    font-weight: 500;
    color: #19233b;
  }

  & > .text {
    max-width: 221px;
    width: 100%;
    letter-spacing: 1.6px;
    font-size: 16px;
    color: #19233b;
  }

  & > input {
    max-width: 221px;
    width: 100%;
    min-height: 34px;
    border: 1px solid #95a7b1;
    border-radius: 6px;
    padding: 5px 10px;

    :focus {
      outline: none;
    }
  }

  & > select {
    max-width: 221px;
    width: 100%;
    height: 34px;
    border: 1px solid #95a7b1;
    border-radius: 6px;
    padding: 5px 10px;
    background: white;

    :focus {
      outline: none;
    }
  }

  & > textarea {
    max-width: 221px;
    width: 100%;
    min-height: 86px;
    border-radius: 8px;
    border: 1px solid #95a7b1;
    padding: 5px 10px;
    resize: none;

    :focus {
      outline: none;
    }
  }

  ${props => props.css || ""};
`;

export const TabItem = styled.div`
  cursor: pointer;
  padding: 3px;
  min-width: 78px;
  text-align: center;
  letter-spacing: 1.6px;
  color: ${props => (props.select ? "#4e77e7" : "rgba(25,35,59,0.60)")};
  border-bottom: ${props =>
    props.select ? "4px solid #4e77e7" : "4px solid transparent"};
`;

export const BlueButton = styled.div`
  cursor: pointer;
  padding: 9px 13px;
  min-width: 150px;
  background: #4e77e7;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 16px;
  color: white;
`;

export const WhiteButton = styled.div`
  cursor: pointer;
  padding: 9px 13px;
  min-width: 150px;
  text-align: center;
  font-size: 16px;
  color: #4e77e7;
`;

export const CreateButton = styled.div`
  background-color: #4e77e7;
  display: flex;
  min-width: 150px;
  cursor: pointer;
  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  & span {
    color: white;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    border-left: 1px solid white;
  }

  @media screen and (max-width: 550px) {
    min-width: 40px;
    & span {
      display: none;
    }
  }
`;

export const OutlineButton = styled.div`
  cursor: pointer;
  min-width: 100px;
  min-height: 40px;
  background: white;
  border: 1px solid #8596a6;

  display: flex;
  justify-content: center;
  align-items: center;

  letter-spacing: 1.6px;
  font-size: 16px;
  color: #8596a6;
`;

export const Absolute = styled.div`
  ${Css.Absolute} ${props => props.css};
`;

export const Figure = styled.figure`
  ${Css.Background} ${props => props.css};
`;

export const Center = styled.div`
  ${Css.Center} ${props => props.css};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || "0 auto"};
  ${props => props.css};
`;

export const Container = styled.div`
  ${props => props.css};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  ${props => props.css};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  ${props => props.css};
`;

export const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => props.color || "teal"};
  color: ${props => props.textColor || "#fff"};
  font-size: ${props => props.fontSize || "20px"};

  & :hover {
    font-weight: 900;
    background: ${props => props.hoverColor || props.color || "teal"};
  }

  & :focus {
    background: ${props => props.focusColor || props.color || "teal"};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    background: ${props => props.disableColor || "#ccc"};
    color: ${props => props.disableTextColor || "#666"};
  }

  ${props => props.css || ""};
`;

export const FlatButton = styled.button`
  display: inline-block;
  border: none;
  padding: 8px 12px;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: font-weight 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  color: ${props => props.color || "black"};
  font-size: ${props => props.fontSize || "16px"};

  & :hover {
    transform: scale(1.05);
    color: ${props => props.color || "black"};
  }

  & :focus {
    color: ${props => props.color || "black"};
    outline: none;
  }

  & :active {
    transform: scale(0.88);
  }

  & :disabled {
    color: ${props => props.color || "#ccc"};
  }

  ${props => props.css || ""};
`;

export const HintText = styled.div`
  padding: 5px;
  font-size: 12px;
  color: rgb(200, 15, 38);
  ${props => props.css || ""};
`;

export const Spinner = styled.div`
  border: 3px solid white;
  border-top-color: lightgray;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 1s linear infinite;

  @keyframes spin {
  to {
    transform: rotate(360deg);
  }

  ${props => props.css || ""};
`;
