const constants = require("../Domain/constants");
const Api = require("./ApiPrimary");
const ApiSls = require("./ApiSls");
const ApiLocal = require("./ApiLocal");
const ApiProject = require("./ApiProject");

const isAwsUser = constants.userType !== "mono";

const ApiBasedOnUserType = {
  getMyProfile: async () => {
    return isAwsUser ? await ApiSls.getMyProfile() : await Api.getMyProfile();
  },
  editMyProfile: async data => {
    return isAwsUser
      ? await ApiSls.editMyProfile(data)
      : await Api.editMyProfile(data);
  },
  fetchProductById: async id => {
    return isAwsUser
      ? await ApiLocal.fetchProductById(id)
      : await Api.fetchProductById(id);
  },
  fetchProducts: async ({ limit, sort, offset, search, label }) => {
    return isAwsUser
      ? await ApiLocal.fetchProducts({ limit, sort, offset, search, label })
      : await Api.fetchProducts({ limit, sort, offset, search, label });
  },
  fetchProductsByStoreId: async id => {
    return isAwsUser
      ? await ApiLocal.fetchProductsByStoreId(id)
      : await Api.fetchProductsByStoreId(id);
  },
  createOrder: async data => {
    return isAwsUser
      ? await ApiSls.createOrder(data)
      : await Api.createOrder(data);
  },
  getOrderList: async () => {
    return isAwsUser ? await ApiSls.getOrderList() : await Api.getOrderList();
  },
  getOrder: async id => {
    return isAwsUser ? await ApiSls.getOrder(id) : await Api.getOrder(id);
  },

  // admin
  listStoreOrder: async ({ store, tokenType, from, to }) => {
    return isAwsUser
      ? await ApiSls.listStoreOrder({ store, tokenType, from, to })
      : await Api.listStoreOrder({ from, to });
  }
};

module.exports = {
  ...Api,
  ...ApiBasedOnUserType,
  sls: ApiSls,
  local: ApiLocal,
  ...ApiProject
};
