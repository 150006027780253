// master branch should never touch this file!
const constants = require("../Domain/constants");
const ApiProxy = require("../ApiProxy");

const isAwsUser = constants.userType !== "mono";

const sls = {};
const local = {};

module.exports = {
  testApiProject: async () => {
    console.log("project api");
  },

  getFactoryOrders: async () => {
    return ApiProxy.get({
      path: "/api/factory_order/"
    });
  },

  searchOrders: async (value, { from, to }) => {
    return ApiProxy.get({
      path: `/api/order/?search=${value}${from ? `&from=${from}` : ""}${
        to ? `&to=${to}` : ""
      }`
    });
  },

  fetchOrdersHistory: async () => {
    return ApiProxy.get({
      path: `/api/order/history/`
    });
  },

  fetchOrdersHistoryById: async id => {
    return ApiProxy.get({
      path: `/api/order/history/?order=${id}`
    });
  },

  searchRepairOrders: async () => {
    //TODO: apply api path!
    return ApiProxy.get({
      path: "/api/repair_order/"
    });
  },

  fetchRepairOrdersHistory: async () => {
    return ApiProxy.get({
      path: `/api/repair_order/history/`
    });
  },

  fetchRepairOrdersHistoryById: async id => {
    return ApiProxy.get({
      path: `/api/repair_order/history/?repair_order=${id}`
    });
  },

  deleteRepairOrderById: async id => {
    return ApiProxy.delete({
      path: `/api/repair_order/${id}`
    });
  },

  // TODO: check this api's goal.
  updateFactoryOrder: async (id, { status, orderIds }) => {
    let data = {
      status,
      order_ids: orderIds
    };
    if (!orderIds) {
      delete data.order_ids;
    }
    if (!status) {
      delete data.status;
    }
    return ApiProxy.put({
      path: `/api/factory_order/${id}`,
      data
    });
  },

  createFactoryOrder: async data => {
    return ApiProxy.post({
      path: `/api/factory_order/`,
      data: {
        ...data
      }
    });
  },

  updateFactoryOrderById: async data => {
    return ApiProxy.put({
      path: `/api/factory_order/${data.id}`,
      data: {
        ...data
      }
    });
  },

  getFactoryOrderById: async id => {
    return ApiProxy.get({
      path: `/api/factory_order/${id}`
    });
  },

  deleteFactoryOrderById: async id => {
    return ApiProxy.delete({
      path: `/api/factory_order/${id}`
    });
  },

  createRepairOrder: async data => {
    return ApiProxy.post({
      path: `/api/repair_order/`,
      data: {
        ...data
      }
    });
  },

  updateRepairOrderById: async data => {
    return ApiProxy.put({
      path: `/api/repair_order/${data.id}`,
      data: {
        ...data
      }
    });
  },

  getRepairOrderById: async id => {
    return ApiProxy.get({
      path: `/api/repair_order/${id}`
    });
  },

  createCommonOrder: async data => {
    return ApiProxy.post({
      path: `/api/order/`,
      data: {
        ...data
      }
    });
  },

  updateCommonOrderById: async data => {
    return ApiProxy.put({
      path: `/api/order/${data.id}`,
      data: {
        ...data
      }
    });
  },

  getCommonOrderById: async id => {
    return ApiProxy.get({
      path: `/api/order/${id}`
    });
  },

  getShoesCustomType: async () => {
    return ApiProxy.get({
      path: `/api/custom_type/`
    });
  },

  cancelOrderById: async data => {
    return ApiProxy.put({
      path: `/api/order/${data.id}`,
      data: {
        amount: data.amount,
        cancel_this_order: true
      }
    });
  },

  getStaffList: async () => {
    return ApiProxy.get({
      path: `/api/staff_management/`
    });
  },

  getStaffById: async id => {
    return ApiProxy.get({
      path: `/api/staff_management/${id}`
    });
  },

  updateStaffById: async data => {
    return ApiProxy.put({
      path: `/api/staff_management/${data.id}`,
      data: {
        ...data
      }
    });
  },

  createStaff: async data => {
    return ApiProxy.post({
      path: `/api/staff_management/`,
      data: {
        ...data
      }
    });
  },

  fetchCommonOrdersByPhone: async phone => {
    return ApiProxy.get({
      path: `/api/order/?phone=${phone}`
    });
  },

  fetchRepairOrdersByPhone: async phone => {
    return ApiProxy.get({
      path: `/api/repair_order/?phone=${phone}`
    });
  },

  fetchStockTypes: async () => {
    return ApiProxy.get({
      path: `/api/stock_type/`
    });
  },

  fetchStockRecords: async () => {
    return ApiProxy.get({
      path: `/api/stock_record/`
    });
  },

  createStockRecord: async data => {
    return ApiProxy.post({
      path: `/api/stock_record/`,
      data: {
        ...data
      }
    });
  },

  updateStockRecordById: async data => {
    return ApiProxy.put({
      path: `/api/stock_record/${data.id}/`,
      data: {
        ...data
      }
    });
  },

  fetchStockRecordById: async data => {
    return ApiProxy.get({
      path: `/api/stock_record/${data}/`
    });
  },

  sendMail: async data => {
    return ApiProxy.post({
      path: `/api/send_mail/`,
      data: {
        ...data
      }
    });
  },

  fetchMembers: async () => {
    return ApiProxy.get({
      path: `/api/member/`
    });
  },

  createMember: async data => {
    return ApiProxy.post({
      path: `/api/member/`,
      data: {
        ...data
      }
    });
  },

  fetchOrdersByMemberId: async id => {
    return ApiProxy.get({
      path: `/api/order/?member=${id}`
    });
  },

  sls,
  local
};
