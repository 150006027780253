const Settings = {
    webUrl: "https://buffet-staff-stg.revtel.tech/",
    apiUrl: "https://buffet-api-stg.revtel.tech",
    mediaHost: "https://d3hgkmxi9caj7a.cloudfront.net",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/rev-ec.revtel.tech",
    debug: true,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-127064537-1",
    userType: "mono",
    slsApi: {
      host: "https://1611qza2rg.execute-api.ap-northeast-1.amazonaws.com/stg",
    },
    aws: {
      tableOrder: "rev-ec.revtel.tech.order",
      tableStoreOrder: "rev-ec.revtel.tech.store-order"
    },
};

module.exports = Settings;

