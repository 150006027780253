import Actions from "./Actions";

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Actions.STORE:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
}

export function getStore(state) {
  return state.data;
}
