import React from "react";
import MuiWrapper from "../../src/MuiWrapper";
import { Helmet } from "react-helmet";

class Layout extends React.Component {
  render() {
    // TODO: remove MUI, use styled-icon
    return (
      <MuiWrapper>
        <Helmet>
          <title>BUFFET Dashboard</title>
          <meta
            name="description"
            content="Revtel Tech 忻旅科技 | 網站、APP、物聯網開發 | 始於專注，終於優質"
          />
          <link rel="shortcut icon" href="/k-store-logo.jpg" />
          <script src="https://unpkg.com/html2canvas@1.0.0-alpha.12/dist/html2canvas.min.js"></script>
          <script src="https://unpkg.com/jspdf@1.5.2/dist/jspdf.min.js"></script>
        </Helmet>

        <div>{this.props.children}</div>
      </MuiWrapper>
    );
  }
}

export default Layout;
