const ApiProxy = require("../ApiProxy");
const constants = require("../Domain/constants");
const SlsHost = constants.slsApi.host;
const { docClientFactory } = require("../../rev-aws-client/generic/dynamo");
const awsDocClient = docClientFactory({ url: `${SlsHost}/dynamo` });

module.exports = {
  async getMyProfile() {
    console.log("get my remote file!");
    let profile = await ApiProxy.get({
      path: `${SlsHost}/signin?token=${ApiProxy.apiToken}`,
      withHost: true
    });
    // TODO: dynamoDB remote user should also support similar data structure of local user
    if (profile) {
      profile = {
        ...profile,
        user: { username: "sls-fake" },
        social_avatar: "sls-fake",
        avatar: "sls-fake",
        phone: "",
        data: ""
      };
    }
    return profile;
  },

  async editMyProfile(data) {
    // TODO: if avatar exists, upload to s3! and then update other data in dynamoDB
    // should create sls edit profile api
    console.log("update my remote file!");
    return Promise.resolve();
  },

  async getCart() {
    return ApiProxy.get({
      path: `${SlsHost}/cart?token=${ApiProxy.apiToken}`,
      withHost: true
    });
  },

  async editCartConfig(config) {
    return ApiProxy.put({
      path: `${SlsHost}/cart?token=${ApiProxy.apiToken}`,
      withHost: true,
      data: { config }
    });
  },

  async addItemToCart({ product, quantity }) {
    return ApiProxy.post({
      path: `${SlsHost}/cart/add?token=${ApiProxy.apiToken}`,
      data: { product, quantity },
      withHost: true
    });
  },

  async removeItemFromCart(index) {
    return ApiProxy.post({
      path: `${SlsHost}/cart/remove?token=${ApiProxy.apiToken}`,
      data: { index },
      withHost: true
    });
  },

  async editItemFromCart({ index, config: { product, quantity } }) {
    return ApiProxy.post({
      path: `${SlsHost}/cart/edit?token=${ApiProxy.apiToken}`,
      data: { index, config: { product, quantity } },
      withHost: true
    });
  },

  async listOrders({ userId }) {
    let resp = await awsDocClient.query(
      {
        TableName: constants.aws.tableOrder,
        IndexName: `buyer-index`,
        KeyConditionExpression: `buyer = :v1`,
        ExpressionAttributeValues: {
          ":v1": `${userId}`
        }
      },
      { token: ApiProxy.apiToken }
    );

    return resp.Items;
  },

  async listStoreOrder({ store, tokenType, from, to }) {
    // TODO: should pass query date in awsDocClient.query, rather than filter after query returned
    let resp = await awsDocClient.query(
      {
        TableName: constants.aws.tableStoreOrder,
        IndexName: `store-index`,
        KeyConditionExpression: `#s = :v1`,
        ExpressionAttributeNames: { "#s": "store" },
        ExpressionAttributeValues: {
          ":v1": `${store}`
        }
      },
      { token: ApiProxy.apiToken, token_type: tokenType }
    );

    let items = resp.Items;

    // from, to format: '2019-09-12'
    items = items.filter(item => {
      let itemCreatedDate = new Date(item.created);
      if (from && from === to) {
        return (
          itemCreatedDate.toLocaleDateString("default", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }) === from
        );
      }
      let localTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
      let itemCreatedTimestamp = itemCreatedDate.getTime();
      if (from) {
        let fromTimestamp = new Date(from).getTime() - localTimezoneOffset;
        if (itemCreatedTimestamp < fromTimestamp) {
          return false;
        }
        if (to) {
          let toTimestamp = new Date(to).getTime() - localTimezoneOffset;
          if (itemCreatedTimestamp > toTimestamp) {
            return false;
          }
        }
      }
      return true;
    });

    return items;
  },

  async createOrder(data) {
    let resultData = { ...data, payment_type: data.paymentType };
    delete resultData.paymentType;
    return ApiProxy.post({
      path: `${SlsHost}/order/create?token=${ApiProxy.apiToken}`,
      data: resultData,
      withHost: true
    });
  },

  async getOrderList() {
    return ApiProxy.get({
      path: `${SlsHost}/orders/?token=${ApiProxy.apiToken}`,
      withHost: true
    });
  },

  async getOrder(id) {
    let profile = await ApiProxy.get({
      path: `${SlsHost}/signin?token=${ApiProxy.apiToken}`,
      withHost: true
    });

    console.log("profile in get order by id:", profile);
    let resp = await awsDocClient.query(
      {
        TableName: constants.aws.tableOrder,
        IndexName: `buyer-index`,
        KeyConditionExpression: `buyer = :v1`,
        ExpressionAttributeValues: {
          ":v1": `${profile.id}`
        }
      },
      { token: ApiProxy.apiToken }
    );

    return (
      resp.Items.find(item => item.id === id) || Promise.reject({ status: 404 })
    );
  }
};
