import { navigate } from "gatsby";
import Actions from "../Reducers/Actions";
import ApiProxy from "../../src/ApiProxy";
import Catchify from "../../src/Utils/Catchify";
import API from "../../src/Api";
import srcActionCreator from "../../src/ActionCreator";

export default dispatch => {
  const srcActions = srcActionCreator(dispatch);

  return {
    navActions: {
      ...srcActions.navActions
    },

    appActions: {
      ...srcActions.appActions,

      getMyStore: async () => {
        try {
          let results = await API.getMyStore();
          dispatch({ type: Actions.STORE, payload: results });
        } catch (err) {
          console.warn(err);
        }
      },

      editStore: async (id, data) => {
        let result = await API.editStore(id, data);
        return result;
      },

      deleteStoreImagesById: async id => {
        try {
          let results = API.deleteStoreImagesById(id);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      createStoreImages: async (id, data) => {
        try {
          let results = API.createStoreImages(id, data);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      deleteProductImagesById: async id => {
        try {
          let results = API.deleteProductImagesById(id);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      createProductImages: async (id, data) => {
        try {
          let results = API.createProductImages(id, data);
          return results;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      },

      listStoreOrder: async ({ store, tokenType, from, to }) => {
        try {
          return API.listStoreOrder({ store, tokenType, from, to });
        } catch (err) {
          return Promise.reject(err);
        }
      },

      searchOrders: async (search, options = { from: "", to: "" }) => {
        return API.searchOrders(search, options);
      },

      fetchOrdersHistory: async () => {
        return API.fetchOrdersHistory();
      },

      fetchOrdersHistoryById: async id => {
        return API.fetchOrdersHistoryById(id);
      },

      searchRepairOrders: async (search, options = { from: "", to: "" }) => {
        return API.searchRepairOrders(search, options);
      },

      fetchRepairOrdersHistory: async () => {
        return API.fetchRepairOrdersHistory();
      },

      fetchRepairOrdersHistoryById: async id => {
        return API.fetchRepairOrdersHistoryById(id);
      },

      getFactoryOrders: async () => {
        return API.getFactoryOrders();
      },

      deleteRepairOrderById: async id => {
        return API.deleteRepairOrderById(id);
      },

      // TODO: check this api's goal.
      updateFactoryOrder: async (id, { status, orderIds }) => {
        return API.updateFactoryOrder(id, { status, orderIds });
      },

      createFactoryOrder: async data => {
        return API.createFactoryOrder(data);
      },

      updateFactoryOrderById: async data => {
        return API.updateFactoryOrderById(data);
      },

      getFactoryOrderById: async id => {
        return API.getFactoryOrderById(id);
      },

      deleteFactoryOrderById: async id => {
        return API.deleteFactoryOrderById(id);
      },

      createRepairOrder: async data => {
        return API.createRepairOrder(data);
      },

      updateRepairOrderById: async data => {
        return API.updateRepairOrderById(data);
      },

      getRepairOrderById: async id => {
        return API.getRepairOrderById(id);
      },

      createCommonOrder: async data => {
        return API.createCommonOrder(data);
      },

      updateCommonOrderById: async data => {
        return API.updateCommonOrderById(data);
      },

      getCommonOrderById: async id => {
        return API.getCommonOrderById(id);
      },

      getShoesCustomType: async () => {
        return API.getShoesCustomType();
      },

      cancelOrderById: async data => {
        return API.cancelOrderById(data);
      },

      getStaffList: async () => {
        return API.getStaffList();
      },

      updateStaffById: async data => {
        return API.updateStaffById(data);
      },

      createStaff: async data => {
        return API.createStaff(data);
      },

      getStaffById: async id => {
        return API.getStaffById(id);
      },

      fetchCommonOrdersByPhone: async phone => {
        return API.fetchCommonOrdersByPhone(phone);
      },

      fetchRepairOrdersByPhone: async phone => {
        return API.fetchRepairOrdersByPhone(phone);
      },

      fetchStockTypes: async () => {
        return API.fetchStockTypes();
      },

      fetchStockRecords: async () => {
        return API.fetchStockRecords();
      },

      createStockRecord: async data => {
        return API.createStockRecord(data);
      },

      updateStockRecordById: async data => {
        return API.updateStockRecordById(data);
      },

      fetchStockRecordById: async data => {
        return API.fetchStockRecordById(data);
      },

      sendMail: async data => {
        return API.sendMail(data);
      },

      fetchMembers: async () => {
        return API.fetchMembers();
      },

      createMember: async data => {
        return API.createMember(data);
      },

      fetchOrdersByMemberId: async id => {
        return API.fetchOrdersByMemberId(id);
      }
    }
  };
};
